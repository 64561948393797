<template>
	<div class="container-fluid g-0">
		<div class="row g-0" v-if="auth">
			<div class="col-md-12 bg-light">
				<div class="container-fluid">
					<div class="row">
						<div class="col-md-4 text-start pt-4">
							<p class="text-dark"><strong>{{ listings.data.listings.length }}</strong> properties to go</p>
						</div>
						<div class="col-md-4 text-center pt-3">
							<input type="text" v-model="edit_id" placeholder="Edit ID" />
							<button class="btn btn-dark ms-3" v-on:click="fetch_published()">Edit</button> &nbsp; 
						</div>
						<div class="col-md-4 text-end pt-3 pb-3">
							<small><strong>User: </strong></small>
							<select v-model="username">
								<option>Neil</option>
								<option>Danae</option>
								<option>Etienne</option>
								<option>Devteam</option>
							</select>
							<button class="btn btn-dark ms-3" v-on:click="fetch_listing()">Load Next</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" v-if="loading">
			<div class="col-12 text-center">
				<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
					<circle class="dot" cx="30" cy="50" r="5" />
					<circle class="dot" cx="50" cy="50" r="5" />
					<circle class="dot" cx="70" cy="50" r="5" />
				</svg>
				<p>{{ message }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12" v-if="property_loaded">
				<div class="row mt-5">
					<div class="col-md-6 px-4">
						<h3>1. Choose featured image</h3>
						<p>This image will appear on the listing banner, as well as in search results.</p>
						<div class="container-fluid">
							<div class="row">
								<div class="col-4" v-for="(item, index) in listing.data.listing[0].listing_images_hd" :key="index">
									<div class="image-container" @click="setFeaturedImage(item, index)">
										<img :src="item" class="img-fluid" :class="{ 'selected-image': isSelectedImage(index) }" />
										<span v-if="isSelectedImage(index)" class="tick-mark">&#10004;</span>
									</div>
								</div>
							</div>
						</div>
						<p v-html="listing.data.listing[0].id"></p>
						<h3 class="mt-5">Original description</h3>
						<p v-html="listing.data.listing[0].description"></p>
					</div>
					<div class="col-md-6">
						<h3 class="">2. Review SEO description</h3>
						<p>These tags appear in both search engine and social sharing options.</p>
						<textarea class="w-100" style="height: 200px;" v-model="listing.ai_seo"></textarea><br/>
						<h3 class="mt-4">3. Review short description</h3>
						<p>These tags appear in both search and sharing options.</p>
						<textarea class="w-100" style="height: 200px;" v-model="listing.ai_description"></textarea><br/>
						<h3 class="mt-4">3. Review long description</h3>
						<p>These tags appear in both search and sharing options.</p>
						<textarea class="w-100" style="height: 200px;" v-model="listing.ai_long_description"></textarea>
						<h3 class="mt-4">4. Location description</h3>
						<p>This content appears on the listing page</p>
						<textarea class="w-100" style="height: 200px;" v-model="listing.ai_location_description"></textarea>
						<h3 class="mt-4">5. Amenities description</h3>
						<p>This content appears on the listing page</p>
						<textarea class="w-100" style="height: 200px;" v-model="listing.ai_amenities_description"></textarea>
						<h3 class="mt-4">6. Schools description</h3>
						<p>This content appears on the listing page</p>
						<textarea class="w-100" style="height: 200px;" v-model="listing.ai_schools_description"></textarea>
						<h3 class="mt-4">7. Activities description</h3>
						<p>This content appears on the listing page</p>
						<textarea class="w-100" style="height: 200px;" v-model="listing.ai_activities_description"></textarea>
						<h3 class="mt-4">9. Select a template</h3>
						<p>This is the display template that the listing will use on it's own page</p>
						<select v-model="listing.template">
							<option>1</option>
							<option>2</option>
							<option>3</option>
							<option>4</option>
							<option>5</option>
							<option>6</option>
						</select>
						<h3 class="mt-4">8. Tags</h3>
						<div>
						<input type="checkbox" v-model="listing.data.listing.tags.buy"> Buy <br/>
						<input type="checkbox" v-model="listing.data.listing.tags.rent"> Rent <br/> 
						<input type="checkbox" v-model="listing.data.listing.tags.inland"> Inland <br/>
						<input type="checkbox" v-model="listing.data.listing.tags.coastal"> Coastal <br/>
						<input type="checkbox" v-model="listing.data.listing.tags.countryside"> Countryside <br/>
						<input type="checkbox" v-model="listing.data.listing.tags.city"> City <br/>
						<input type="checkbox" v-model="listing.data.listing.tags.commercial"> Commercial <br/>
						</div>

						<h3 class="mt-4">9. Marketing Title</h3>
						<textarea v-model="listing.data.listing[0].marketing_heading"></textarea> <br/>

						<h3 class="mt-4">10. Exclusion reason (not required)</h3>
						<textarea v-model="exclusion"></textarea> <br/>

						<button class="btn btn-primary" v-on:click="publish_listing()" v-if="this.edit_id == ''">Publish</button>
						<button class="btn btn-primary" v-on:click="publish_edits()" v-if="this.edit_id != ''">Edit</button>

						<div class="mb-5 pb-5"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios'

export default {
	name: 'Workspace',
	data() {
		return {
			username: '',
			claim: '',
			counter: 0,
			listings: {
				data: {
					listings: []
				}
			},
			exclusion: '',
			listing: {
				ai_seo: '',
				ai_description: '',
				ai_long_description: '',
				ai_location_description: '',
				ai_amenities_description: '',
				ai_activities_description: '',
				ai_schools_description: '',
				featured_image: '',
				template: 1,
				is_featured_property: true,
				tags: {
					buy: false,
					rent: false,
					inland: false,
					coastal: false,
					countryside: false,
					city: false,
					commercial: false
				}
			},
			message: '',
			loading: false,
			property_loaded: false,
			selectedImageIndex: null,
			sale_type: '',
			space: '',
			state: 'publishing',
			edit_id: '',
			auth: false
		}
	},
	mounted() {
		const params = new URLSearchParams(window.location.search);
		if (params.get('key') === '241389732987492') {
			this.fetch_listings()
			this.auth = true
		} else {
			console.log("No auth key provided");
		}
	},
	methods: {
		offset: async function () {
			await axios
			.get('https://luxury-dev.chaseveritt.co.za/api/offset.php',
			{
				headers: {
					'Accept': 'application/json',
				}
			})
			.then(response => (console.log(response.data)))
			.catch(error => (console.log(error)))
		},
		fetch_offset: async function () {
			await axios
			.get('https://luxury-dev.chaseveritt.co.za/api/fetch_offset.php',
			{
				headers: {
					'Accept': 'application/json',
				}
			})
			.then(response => (this.counter = response.data))
			.catch(error => (console.log(error)))
		},
		setFeaturedImage(item, index) {
			this.selectedImageIndex = index
			this.listing.featured_image = item
		},
		isSelectedImage(index) {
			return this.selectedImageIndex === index
		},
		fetch_next: async function () {
			this.property_loaded = false
			await this.offset()
			await this.fetch_listing()
		},
		fetch_published: async function () {
			this.loading = true
			this.$store.state.agentMails = []
			try {
				const response = await axios.post('https://luxury-dev.chaseveritt.co.za/api/fetch_listing.php', {
				id: this.edit_id
				}, {
				headers: {
					'Accept': 'application/json',
				}
				});
				this.listing = response.data.listing
				console.log(this.listing)
			} catch (error) {
				console.log(error)
			}
			this.loading = false
			this.property_loaded = true
		},
		publish_edits: async function () {
			await axios
			.post('https://luxury-dev.chaseveritt.co.za/api/edit.php',
			{ 
				listing: this.listing
			},
			{
				headers: {
					'Accept': 'application/json',
				}
			})
			.then(response => (console.log(response.data)))
			.catch(error => (console.log(error)))
			alert("Done")
		},
		publish_listing: async function () {
			this.listing.is_featured_property = false
			this.listing.tags = {
				buy: this.listing.data.listing.tags.buy,
				rent: this.listing.data.listing.tags.rent,
				coastal: this.listing.data.listing.tags.coastal,
				inland: this.listing.data.listing.tags.inland,
				countryside: this.listing.data.listing.tags.countryside,
				city: this.listing.data.listing.tags.city,
				commercial: this.listing.data.listing.tags.commercial
			}

			this.listing.exclusion = this.exclusion
		
			await axios
			.post('https://luxury-dev.chaseveritt.co.za/api/publish.php',
			{ 
				listing: this.listing
			},
			{
				headers: {
					'Accept': 'application/json',
				}
			})
			.then(response => (console.log(response.data)))
			.catch(error => (console.log(error)))

			await axios
			.get('https://luxury-dev.chaseveritt.co.za/api/claim.php?user=' + this.username + '&claim=0',
			{
				headers: {
					'Accept': 'application/json',
				}
			})
			.then(response => (console.log(response.data)))
			.catch(error => (console.log(error)))

			console.log('Listing published')
			this.property_loaded = false
			await this.fetch_listings()

		},
		fetch_listings: async function () {
			await axios
			.get('https://luxury-dev.chaseveritt.co.za/api/listings.php',
			{
				headers: {
					'Accept': 'application/json',
				}
			})
			.then(response => (this.listings = response.data))
			.catch(error => (console.log(error)))
		},
		fetch_listing: async function () {

			this.loading = true

			await axios
			.get('https://luxury-dev.chaseveritt.co.za/api/fetch_claims.php?user=' + this.username,
			{
				headers: {
					'Accept': 'application/json',
				}
			})
			.then(response => (this.claim = response.data))
			.catch(error => (console.log(error)))

			if (this.claim !== 0) {
				this.listings.data.listings[0].id = this.claim
			} else {
				await axios
				.get('https://luxury-dev.chaseveritt.co.za/api/claim.php?user=' + this.username + '&claim=' + this.listings.data.listings[0].id,
				{
					headers: {
						'Accept': 'application/json',
					}
				})
				.then(response => (console.log(response.data)))
				.catch(error => (console.log(error)))
			}

			await axios
			.post('https://luxury-dev.chaseveritt.co.za/api/listing.php',
			{
				id: this.listings.data.listings[0].id
			},
			{
				headers: {
					'Accept': 'application/json',
				}
			})
			.then(response => (this.listing = response.data))
			.catch(error => (console.log(error)))

			localStorage.setItem('claimed', this.listings.data.listings[0].id)

			this.message = 'Generating SEO'
			
			await axios.post('https://api.openai.com/v1/completions', {
				model: "gpt-3.5-turbo-instruct",
				temperature: 0.2,
				prompt: ' Generate SEO metatags and OG sharing tags for this property listing: ' + this.listing.data.listing[0].description + 
						' Respond with the HTML I would use in my page header and nothing else. ' + 
						' Never include anything BUT the html exactly as it would be in my header.' +
						' Proofread and edit the descriptions to sound luxurious using luxurious adjectives.' +
						' Include Twitter, Instagram and Facebook tags.' + 
						' Always include this image reference:' + this.listing.data.listing[0].listing_images[0] +
						' The URL to this page is in this format: https://luxury-dev.chaseveritt.co.za/property/' + this.listing.data.listing[0].id,
				max_tokens: 500
			}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + 'sk-5EFn37rPTunLyKIWIb2JznuC0rBXLnvcynfCHWauRTT3BlbkFJUzfwhJXfyjFHZjIbc-S5sWeDHsCCV-F-udTuUeGLAA'
				}
			})
			.then(response => {
				this.listing.ai_seo = response.data.choices[0].text.trim()
			})
			.catch(error => ( console.log(error) ))

			this.message = 'Generating SEO'
			
			await axios.post('https://api.openai.com/v1/completions', {
				model: "gpt-3.5-turbo-instruct",
				temperature: 0.2,
				prompt: ' Generate SEO metatags and OG sharing tags for this property listing: ' + this.listing.data.listing[0] + 
						' Respond with the HTML I would use in my page header and nothing else. Never include anything BUT the html exactly as it would be in my header.' +
						' Proofread and edit the descriptions to sound luxurious using luxurious adjectives.' +
						' Include Twitter, Instagram and Facebook tags.' + 
						' Always include this image reference:' + this.listing.data.listing[0].listing_images[0] +
						' The URL to this page is in this format: https://luxury-dev.chaseveritt.co.za/property/' + this.listing.data.listing[0].id,
				max_tokens: 500
			}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + 'sk-5EFn37rPTunLyKIWIb2JznuC0rBXLnvcynfCHWauRTT3BlbkFJUzfwhJXfyjFHZjIbc-S5sWeDHsCCV-F-udTuUeGLAA'
				}
			})
			.then(response => {
				this.listing.ai_seo = response.data.choices[0].text.trim()
			})
			.catch(error => ( console.log(error) ))

			this.message = 'Generating short description'
			
			await axios.post('https://api.openai.com/v1/completions', {
				model: "gpt-3.5-turbo-instruct",
				temperature: 0.2,
				prompt: ' Rewrite the following : ' + this.listing.data.listing[0].description + 
						' Strip out any HTML that is already in the description.' +
						' Make it a single paragraph and not longer than 30 words. ' + 
						' Include luxurious adjectives. Make it sound as luxurious as possible.' + 
						' Always use standard sentence case for your response. Never capatalise all the letters in a sentence. These properties are always in South Africa. Never reference another country, ever.',
				max_tokens: 500
			}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + 'sk-5EFn37rPTunLyKIWIb2JznuC0rBXLnvcynfCHWauRTT3BlbkFJUzfwhJXfyjFHZjIbc-S5sWeDHsCCV-F-udTuUeGLAA'
				}
			})
			.then(response => {
				this.listing.ai_description = response.data.choices[0].text.trim()
			})

			this.message = 'Generating long description'

			await axios.post('https://api.openai.com/v1/completions', {
				model: "gpt-3.5-turbo-instruct",
				temperature: 0.2,
				prompt: ' Edit the following description for the following property: ' + this.listing.data.listing[0].description + 
						' Be as descriptive as possible. Use as many luxurious adjectives as possible. Make it as long as possible.' +
						' Use the provided descriptions as much as possible.' +
						' Strip out any HTML that is already in the descriptions.' +
						' Add new HTML that creates SEO for the descriptions. Remove [object] references.' + 
						' Include luxurious adjectives.' + 
						' These properties are always in South Africa.',
				max_tokens: 500
			}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + 'sk-5EFn37rPTunLyKIWIb2JznuC0rBXLnvcynfCHWauRTT3BlbkFJUzfwhJXfyjFHZjIbc-S5sWeDHsCCV-F-udTuUeGLAA'
				}
			})
			.then(response => {
				this.listing.ai_long_description = response.data.choices[0].text.trim()
			})
			.catch(error => ( console.log(error) ))

			this.message = 'Generating location description'

			await axios.post('https://api.openai.com/v1/completions', {
				model: "gpt-3.5-turbo-instruct",
				temperature: 0.2,
				prompt: ' Write a short description for the following location: ' + this.listing.data.listing[0].loc_suburb + ',' + this.listing.data.listing[0].loc_province + ',' + this.listing.data.listing[0].loc_area + "," + this.listing.data.listing[0].loc_country + 
						' This is for a luxury property website.' +
						' Be as descriptive as possible and pay special attention to the country I provide. Do not change the country and look for the location in the data I provided. ' +
						' Use the provided descriptions as much as possible.' +
						' Strip out any HTML that is already in the descriptions. ',
				max_tokens: 500
			}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + 'sk-5EFn37rPTunLyKIWIb2JznuC0rBXLnvcynfCHWauRTT3BlbkFJUzfwhJXfyjFHZjIbc-S5sWeDHsCCV-F-udTuUeGLAA'
				}
			})
			.then(response => {
				this.listing.ai_location_description = response.data.choices[0].text.trim()
			})
			.catch(error => ( console.log(error) ))

			let json = JSON.stringify(this.listing.data.listing[0])

			this.message = 'Generating amenities description'

			await axios.post('https://api.openai.com/v1/completions', {
				model: "gpt-3.5-turbo-instruct",
				temperature: 0.2,
				prompt: ' Write a bullet point list of the amenities in this property: ' + json + 
						' This is for a luxury property website.' +
						' Be as descriptive as possible.' +
						' Use the provided descriptions as much as possible.' +
						' Strip out any HTML that is already in the descriptions.',
				max_tokens: 100
			}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + 'sk-5EFn37rPTunLyKIWIb2JznuC0rBXLnvcynfCHWauRTT3BlbkFJUzfwhJXfyjFHZjIbc-S5sWeDHsCCV-F-udTuUeGLAA'
				}
			})
			.then(response => {
				this.listing.ai_amenities_description = response.data.choices[0].text.trim()
			})
			.catch(error => ( console.log(error) ))

			this.message = 'Generating schools description'

			await axios.post('https://api.openai.com/v1/completions', {
				model: "gpt-3.5-turbo-instruct",
				temperature: 0.3,
				prompt: ' Write a paragraph the schools and universities in or near to this area: ' + this.listing.data.listing[0].loc_suburb + ',' + this.listing.data.listing[0].loc_province + ',' + this.listing.data.listing[0].loc_area + "," + this.listing.data.listing[0].loc_country +
						' This is for a luxury property website.' +
						' Be as descriptive as possible.' +
						' Use the provided descriptions as much as possible.' +
						' Never reference the area, please. I dont want to mention the area name at all.' +
						' and always include the closest school AND university',
				max_tokens: 300
			}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + 'sk-5EFn37rPTunLyKIWIb2JznuC0rBXLnvcynfCHWauRTT3BlbkFJUzfwhJXfyjFHZjIbc-S5sWeDHsCCV-F-udTuUeGLAA'
				}
			})
			.then(response => {
				this.listing.ai_schools_description = response.data.choices[0].text.trim()
			})
			.catch(error => ( console.log(error) ))

			this.message = 'Generating schools description'

			await axios.post('https://api.openai.com/v1/completions', {
				model: "gpt-3.5-turbo-instruct",
				temperature: 0.3,
				prompt: ' Write a paragraph about the activities in or near to this area: ' + this.listing.data.listing[0].loc_suburb + ',' + this.listing.data.listing[0].loc_province + ',' + this.listing.data.listing[0].loc_area + "," + this.listing.data.listing[0].loc_country +
						' This is for a luxury property website.' +
						' Be as descriptive as possible.' +
						' Always include at least 4 notable activities. Reference them by name. ' +
						' Never reference the area, please. I dont want to mention the area name at all. This is not for holidays, its for homeowners. ',
				max_tokens: 300
			}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + 'sk-5EFn37rPTunLyKIWIb2JznuC0rBXLnvcynfCHWauRTT3BlbkFJUzfwhJXfyjFHZjIbc-S5sWeDHsCCV-F-udTuUeGLAA'
				}
			})
			.then(response => {
				this.listing.ai_activities_description = response.data.choices[0].text.trim()
			})
			.catch(error => ( console.log(error) ))

			this.loading = false
			this.property_loaded = true
		}
	}
}
</script>
<style scoped>
.btn {
	font-size: 10px;
	font-weight: bold;
}
.dot {
fill: #3498db;
animation: expand 1s infinite ease-in-out alternate;
}

@keyframes expand {
0% {
	r: 5;
	opacity: 1;
}
100% {
	r: 15;
	opacity: 0;
}
}

.dot:nth-child(2) {
animation-delay: 0.2s;
}

.dot:nth-child(3) {
animation-delay: 0.4s;
}

.image-container {
  position: relative;
  display: inline-block;
}

.tick-mark {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff; /* You can change the color as desired */
  font-size: 32px; /* You can adjust the font size as desired */
}
.selected-image {
  filter: brightness(30%); /* Adjust the brightness level as desired */
}
</style>